import { createReducer } from "reduxsauce";
import Types from "./types";
import saveCookie from "../../../utils/helpers/save-cookie";
import { CAR_COMPARE_CONST, NUMBER } from "../../../constants/app-constants";

export const INITIAL_STATE = {
    content: [],
    carComparision: {
        showCarComparision: false,
        carIds: [],
        carCompareData: []
    },
    metaContent: {
        metaTitle: null,
        metaDescription: null,
        metaKeyWord: null,
        heading: null
    },
    totalCars: 0,
    totalPages: null,
    isSSR: false,
    page: 0,
    isLoading: null,
    error: null,
    config: {},
    shouldReloadList: false,
    dynamicContent: {},
    personalised: false,
    ipDetectedState: "",
    similarCarsContent: [],
    totalSimilarCars: 0,
    totalSimilarPages: null,
    isSimilarCarLoading: null,
    similarCarPage: -1,
    faqData: {},
    loadingFaq: false,
    buyWithConfidence: [],
    seenClpKbbBanner: true,
    showFinanceCentricListing: false,
    showCrmVaraint: false,
    cmsConfig: {
        isCmsConfigLoading: false,
        data: {},
        error: null
    }
};

export const fetchCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchCarListSuccess = (state = INITIAL_STATE, { data, params }) => {
    return {
        ...state,
        carComparision: { ...state.carComparision},
        content: params.page === 0 ? [...data.results] : [...state.content, ...data.results],
        metaContent: { ...data.metaContent },
        dynamicContent: params.page === 0 ? { ...data.dynamicContent} : { ...state.dynamicContent },
        totalCars: data.total,
        totalPages: data.totalPages,
        personalised: data.personalised,
        buyWithConfidence: data.buyWithConfidence || [],
        isLoading: false,
        error: null,
        ...(params && params.page >= 0 && { page: params.page }),
        config: data.config,
        ipDetectedState: data.ipDetectedState
    };
};

export const fetchCarListFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isLoading: false
    };
};

export const fetchSimilarCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSimilarCarLoading: true
    };
};

export const fetchSimilarCarFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isSimilarCarLoading: false
    };
};

export const fetchSimilarCarSuccess = (state = INITIAL_STATE, { data, params }) => {
    return {
        ...state,
        totalSimilarCars: data.total,
        totalSimilarPages: Math.ceil(data.total / NUMBER.TWENTY),
        similarCarsContent: [...state.similarCarsContent, ...data.results || []],
        personalised: data.personalised,
        buyWithConfidence: data.buyWithConfidence || [],
        ...(params && params.page >= 0 && { similarCarPage: params.page }),
        isSimilarCarLoading: false,
        error: null,
        config: data.config,
        ipDetectedState: data.ipDetectedState
    };
};
export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return {
        ...state,
        isSSR
    };
};

export const reloadCarList = (state = INITIAL_STATE, { shouldReloadList }) => {
    return {
        ...state,
        shouldReloadList
    };
};

export const handleCarComparePopup = (state = INITIAL_STATE, { showCarComparision }) => {
    return {
        ...state,
        carComparision: {
            ...state.carComparision,
            showCarComparision
        }
    };
};

export const addCarToCompare = (state = INITIAL_STATE, { carDetails }) => {
    const carComparisionState = {
        ...state.carComparision,
        carCompareData: [
            ...state.carComparision.carCompareData,
            carDetails
        ],
        carIds: [
            ...state.carComparision.carIds,
            carDetails.carId
        ]
    };
    saveCookie(CAR_COMPARE_CONST.STORAGE_KEY, JSON.stringify({ ...carComparisionState, showCarComparision: false }));  // saving compare feature state in local storage
    return {
        ...state,
        carComparision: carComparisionState
    };
};

export const removeCarFromCompare = (state = INITIAL_STATE, { carId }) => {
    const carCompareData = [...state.carComparision.carCompareData.filter((car) => car.carId !== carId)];
    const carIds = [...state.carComparision.carIds.filter((id) => id !== carId)];
    const carComparisionState = {
        ...state.carComparision,
        carCompareData,
        carIds
    };
    saveCookie(CAR_COMPARE_CONST.STORAGE_KEY, JSON.stringify({ ...carComparisionState, showCarComparision: false }));    // saving compare feature state in cookies
    return {
        ...state,
        carComparision: carComparisionState
    };
};

export const fetchCarCompareSuccess = (state = INITIAL_STATE, { carCompareData }) => {
    const carIds = carCompareData.map(({carId}) => carId);
    const carComparisionState = {
        carCompareData,
        carIds,
        showCarComparision: false
    };
    saveCookie(CAR_COMPARE_CONST.STORAGE_KEY, JSON.stringify({ ...carComparisionState}));
    return {
        ...state,
        carComparision: carComparisionState
    };
};

export const updateFinanceCentricListing = (state = INITIAL_STATE, { showFinanceCentricListing }) => {
    return {
        ...state,
        showFinanceCentricListing
    };
};

export const updateCrmVaraintListing = (state = INITIAL_STATE, { showCrmVaraint }) => {
    return {
        ...state,
        showCrmVaraint
    };
};

export const cmsConfigInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCmsConfigLoading: true
    };
};

export const cmsConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        cmsConfig: data,
        isCmsConfigLoading: false
    };
};

export const cmsConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isCmsConfigLoading: false
    };
};

export const resetCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        content: [],
        totalPages: null,
        cmsConfig: {
            ...state.cmsConfig
        }
    };
};

export const HANDLERS = {
    [Types.FETCH_CAR_LIST]: fetchCarList,
    [Types.FETCH_CAR_LIST_SUCCESS]: fetchCarListSuccess,
    [Types.FETCH_SIMILAR_CAR_LIST_SUCCESS]: fetchSimilarCarSuccess,
    [Types.FETCH_CAR_LIST_FAILURE]: fetchCarListFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.RELOAD_CAR_LIST]: reloadCarList,
    [Types.HANDLE_CAR_COMPARE_POPUP]: handleCarComparePopup,
    [Types.ADD_CAR_TO_COMPARE]: addCarToCompare,
    [Types.REMOVE_CAR_FROM_COMPARE]: removeCarFromCompare,
    [Types.FETCH_CAR_COMPARE_SUCCESS]: fetchCarCompareSuccess,
    [Types.UPDATE_FINANCE_CENTRIC_LISTING]: updateFinanceCentricListing,
    [Types.UPDATE_CRM_VARIANT]: updateCrmVaraintListing,
    [Types.CMS_CONFIG_INIT]: cmsConfigInit,
    [Types.CMS_CONFIG_SUCCESS]: cmsConfigSuccess,
    [Types.CMS_CONFIG_FAILURE]: cmsConfigFailure,
    [Types.RESET_CAR_LIST]: resetCarList
};

export default createReducer(INITIAL_STATE, HANDLERS);
