import { createReducer } from "reduxsauce";
import Types from "./types";

export const SCREEN_TYPE = {
    LANDING_PAGE: "LANDING_PAGE",
    CART_VIEW: "CART_VIEW",
    VAS_STORE: "VAS_STORE"
};

const VAS_ITEMS_INITIAL_STATE = {
    options: [],
    metadata: {
        banner: null,
        items: [],
        highlights: []
    }
};

const VAS_BUNDLES_INITIAL_STATE = {
    leastBundleDiscountedPrice: null,
    leastBundleRepaymentPerWeek: null,
    leastProductDiscountedPrice: null,
    leastProductRepaymentPerWeek: null,
    bundles: [],
    products: []
};

const VAS_CART_INITIAL_STATE = {
    loading: false,
    error: null,
    data: {
        items: [],
        value: {
            total: null
        }
    }
};

const INITIAL_STATE = {
    activeStore: undefined,
    showCounterAnimation: false,
    screenType: SCREEN_TYPE.LANDING_PAGE,
    vasBundles: {
        data: VAS_BUNDLES_INITIAL_STATE,
        error: null,
        loading: false
    },
    vasItems: {
        loading: false,
        error: null,
        data: VAS_ITEMS_INITIAL_STATE
    },
    vasProductDetail: {
        loading: false,
        error: null,
        data: {
            metadata: {},
            value: {}
        }
    },
    addVASItem: {
        error: null,
        key: null,
        loading: false
    },
    removeVASItem: {
        error: null,
        key: null,
        loading: false
    },
    vasCart: {
        ...VAS_CART_INITIAL_STATE
    },
    clearVASCart: {
        error: null,
        key: null,
        loading: false
    },
    carCoverBundles: {
        data: {},
        loading: false,
        error: null
    }
};

const getVASBundlesRequest = (state = INITIAL_STATE) => ({
    ...state,
    vasBundles: {
        ...state.vasBundles,
        loading: true,
        error: null
    }
});

const getVASBundlesFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    vasBundles: {
        ...state.vasBundles,
        loading: false,
        error
    }
});

const getVASBundlesSuccess = (state = INITIAL_STATE, {data}) => ({
    ...state,
    vasBundles: {
        ...state.vasBundles,
        error: null,
        loading: false,
        data
    }
});

const getVASItemsRequest = (state = INITIAL_STATE) => ({
    ...state,
    vasItems: {
        ...state.vasItems,
        loading: true,
        error: null
    }
});

const getVASItemsFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    vasItems: {
        ...state.vasItems,
        loading: false,
        error
    }
});

const getVASItemsSuccess = (state = INITIAL_STATE, {data}) => ({
    ...state,
    vasItems: {
        ...state.vasItems,
        loading: false,
        error: null,
        data
    }
});

const getServicingPriceConfigRequest = (state = INITIAL_STATE) => ({
    ...state,
    servicingPriceConfig: {
        ...state.servicingPriceConfig,
        loading: true,
        error: null
    }
});

const getServicingPriceConfigFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    servicingPriceConfig: {
        ...state.servicingPriceConfig,
        loading: false,
        error
    }
});

const getServicingPriceConfigSuccess = (state = INITIAL_STATE, {data}) => ({
    ...state,
    servicingPriceConfig: {
        ...state.servicingPriceConfig,
        loading: false,
        error: null,
        data
    }
});

const getCarCoverBundlesRequest = (state = INITIAL_STATE) => ({
    ...state,
    carCoverBundles: {
        ...state.carCoverBundles,
        loading: true,
        error: null
    }
});

const getCarCoverBundlesFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    carCoverBundles: {
        ...state.carCoverBundles,
        loading: false,
        error
    }
});

const getCarCoverBundlesSuccess = (state = INITIAL_STATE, {data}) => ({
    ...state,
    carCoverBundles: {
        ...state.carCoverBundles,
        loading: false,
        error: null,
        data
    }
});

const getPostBcLandingPlansRequest = (state = INITIAL_STATE) => ({
    ...state,
    postBcLandingPlans: {
        ...state.postBcLandingPlans,
        loading: true,
        error: null
    }
});

const getPostBcLandingPlansFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    postBcLandingPlans: {
        ...state.postBcLandingPlans,
        loading: false,
        error
    }
});

const getPostBcLandingPlansSuccess = (state = INITIAL_STATE, {data}) => ({
    ...state,
    postBcLandingPlans: {
        ...state.postBcLandingPlans,
        loading: false,
        error: null,
        data
    }
});

const completePostBcUpdateStep = (state = INITIAL_STATE, {key}) => {
    return ({
        ...state,
        postBcLandingPlans: {
            ...state.postBcLandingPlans,
            data: {
                ...state.postBcLandingPlans.data,
                optionsList: state.postBcLandingPlans.data.optionsList.map((option) => ({
                    ...option,
                    isStepCompleted: option.categoryKey === key ? true : option.isStepCompleted
                }))
            }
        }
    });
};

const addVASItemRequest = (state = INITIAL_STATE, {data}) => ({
    ...state,
    addVASItem: {
        ...state.addVASItem,
        loading: true,
        error: null,
        key: data
    }
});

const addVASItemFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    addVASItem: {
        ...state.addVASItem,
        loading: false,
        key: null,
        error
    }
});

const addVASItemSuccess = (state = INITIAL_STATE) => ({
    ...state,
    addVASItem: {
        ...state.addVASItem,
        loading: false,
        error: null,
        key: null
    }
});

const removeVASItemRequest = (state = INITIAL_STATE, {data}) => ({
    ...state,
    removeVASItem: {
        ...state.removeVASItem,
        loading: true,
        error: null,
        key: data
    }
});

const removeVASItemFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    removeVASItem: {
        ...state.removeVASItem,
        loading: false,
        error,
        key: null
    }
});

const removeVASItemSuccess = (state = INITIAL_STATE) => ({
    ...state,
    removeVASItem: {
        ...state.removeVASItem,
        loading: false,
        error: null,
        key: null
    }
});

const getVASCartRequest = (state = INITIAL_STATE) => ({
    ...state,
    vasCart: {
        ...state.vasCart,
        loading: true,
        error: null
    }
});

const getVASCartFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    vasCart: {
        ...state.vasCart,
        loading: false,
        error
    }
});

const getVASCartSuccess = (state = INITIAL_STATE, {data}) => ({
    ...state,
    vasCart: {
        ...state.vasCart,
        loading: false,
        data,
        error: null
    }
});

const clearVASCartRequest = (state = INITIAL_STATE) => ({
    ...state,
    clearVASCart: {
        ...state.clearVASCart,
        loading: true
    }
});

const clearVASCartFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    clearVASCart: {
        ...state.clearVASCart,
        loading: false,
        error
    }
});

const clearVASCartSuccess = (state = INITIAL_STATE) => ({
    ...state,
    clearVASCart: {
        ...state.clearVASCart,
        loading: false,
        error: null
    }
});

const setCounterAnimation = (state = INITIAL_STATE, {data}) => ({
    ...state,
    showCounterAnimation: data
});

const setAddonScreenType = (state = INITIAL_STATE, {data: { screenType, activeStore}}) => ({
    ...state,
    screenType,
    activeStore: activeStore || undefined
});

const getVASProductDetailRequest = (state = INITIAL_STATE) => ({
    ...state,
    vasProductDetail: {
        ...state.vasProductDetail,
        data: null,
        loading: true,
        error: null
    }
});

const getVASProductDetailFailure = (state = INITIAL_STATE, {error}) => ({
    ...state,
    vasProductDetail: {
        ...state.vasProductDetail,
        data: null,
        loading: false,
        error
    }
});

const getVASProductDetailSuccess = (state = INITIAL_STATE, { data }) => ({
    ...state,
    vasProductDetail: {
        ...state.vasProductDetail,
        data,
        loading: false,
        error: null
    }
});

const getPostBcVASCartSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        postBcVasCart: {
            ...state.postBcVasCart,
            ...data,
            loading: false,
            error: null
        }
    };
};

const updatePostBcStep = (state = INITIAL_STATE, { data }) => {
    return ({
        ...state,
        postBcStepStatus: data
    });
};

const resetVasCartDetails = (state = INITIAL_STATE) => {
    return ({
        ...state,
        vasCart: VAS_CART_INITIAL_STATE
    });
};

export const HANDLERS = {
    [Types.GET_VAS_BUNDLES_REQUEST]: getVASBundlesRequest,
    [Types.GET_VAS_BUNDLES_SUCCESS]: getVASBundlesSuccess,
    [Types.GET_VAS_BUNDLES_FAILURE]: getVASBundlesFailure,

    [Types.GET_VAS_ITEMS_REQUEST]: getVASItemsRequest,
    [Types.GET_VAS_ITEMS_SUCCESS]: getVASItemsSuccess,
    [Types.GET_VAS_ITEMS_FAILURE]: getVASItemsFailure,

    [Types.GET_SERVICING_PRICE_CONFIG_REQUEST]: getServicingPriceConfigRequest,
    [Types.GET_SERVICING_PRICE_CONFIG_SUCCESS]: getServicingPriceConfigSuccess,
    [Types.GET_SERVICING_PRICE_CONFIG_FAILURE]: getServicingPriceConfigFailure,

    [Types.GET_CAR_COVER_BUNDLES_REQUEST]: getCarCoverBundlesRequest,
    [Types.GET_CAR_COVER_BUNDLES_SUCCESS]: getCarCoverBundlesSuccess,
    [Types.GET_CAR_COVER_BUNDLES_FAILURE]: getCarCoverBundlesFailure,

    [Types.GET_POST_BC_LANDING_PLANS_REQUEST]: getPostBcLandingPlansRequest,
    [Types.GET_POST_BC_LANDING_PLANS_SUCCESS]: getPostBcLandingPlansSuccess,
    [Types.GET_POST_BC_LANDING_PLANS_FAILURE]: getPostBcLandingPlansFailure,

    [Types.ADD_VAS_ITEM_REQUEST]: addVASItemRequest,
    [Types.ADD_VAS_ITEM_SUCCESS]: addVASItemSuccess,
    [Types.ADD_VAS_ITEM_FAILURE]: addVASItemFailure,

    [Types.REMOVE_VAS_ITEM_REQUEST]: removeVASItemRequest,
    [Types.REMOVE_VAS_ITEM_SUCCESS]: removeVASItemSuccess,
    [Types.REMOVE_VAS_ITEM_FAILURE]: removeVASItemFailure,

    [Types.GET_VAS_CART_REQUEST]: getVASCartRequest,
    [Types.GET_VAS_CART_SUCCESS]: getVASCartSuccess,
    [Types.GET_VAS_CART_FAILURE]: getVASCartFailure,

    [Types.CLEAR_VAS_CART_REQUEST]: clearVASCartRequest,
    [Types.CLEAR_VAS_CART_SUCCESS]: clearVASCartSuccess,
    [Types.CLEAR_VAS_CART_FAILURE]: clearVASCartFailure,

    [Types.SET_ADDONS_SCREEN_TYPE]: setAddonScreenType,
    [Types.SET_COUNTER_ANIMATION]: setCounterAnimation,

    [Types.GET_VAS_PRODUCT_DETAIL_REQUEST]: getVASProductDetailRequest,
    [Types.GET_VAS_PRODUCT_DETAIL_SUCCESS]: getVASProductDetailSuccess,
    [Types.GET_VAS_PRODUCT_DETAIL_FAILURE]: getVASProductDetailFailure,

    [Types.COMPLETE_POST_BC_UPDATE_STEP]: completePostBcUpdateStep,
    [Types.GET_POST_BC_VAS_CART_SUCCESS]: getPostBcVASCartSuccess,
    [Types.GET_POST_BC_STEP]: updatePostBcStep,

    [Types.RESET_VAS_CART_DETAILS]: resetVasCartDetails
};

export default createReducer(INITIAL_STATE, HANDLERS);

